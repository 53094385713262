import { RingType } from '@jouzen/outo-toolkit-vuetify'

import { ChartCounts, FirmwareVersions, FwChartData, OldFirmwareVersions } from '#types/stats'

export function getCounts(ringTypes: RingType[]) {
  return ringTypes.reduce(
    (acc: any, curr: RingType) => {
      acc[curr.value] = 0
      acc.androidCount[curr.value] = 0
      acc.iosCount[curr.value] = 0
      return acc
    },
    { androidCount: {}, iosCount: {}, ios: 0, android: 0 },
  ) as ChartCounts
}

export function getOlderVersions(ringTypes: RingType[]) {
  return ringTypes.reduce(
    (acc: any, curr: RingType) => {
      acc[curr.value] = []
      return acc
    },
    { ios: [], android: [] },
  ) as OldFirmwareVersions
}

export function getFilteredFwVersions(ringTypes: RingType[]) {
  return ringTypes.reduce((acc: any, curr: RingType) => {
    acc[curr.value] = []
    return acc
  }, {}) as FirmwareVersions
}

export function getFwChartData(
  filteredFwVersions: FirmwareVersions,
  olderVersions: OldFirmwareVersions,
  totalCounts: ChartCounts,
  ringTypes: RingType[],
) {
  const charts: {
    [key: string]: FwChartData
  } = {}

  ringTypes.forEach((type) => {
    charts[type.value] = {
      data: filteredFwVersions[type.value],
      olderVersions: olderVersions[type.value],
      xAxes: 'firmwareVersion',
      title: `All (${totalCounts[type.value]})`,
      count: totalCounts[type.value] as number,
      series: [
        { name: `android (${totalCounts.androidCount[type.value]})`, field: 'androidCount', stacked: true },
        { name: `ios (${totalCounts.iosCount[type.value]})`, field: 'iosCount', stacked: true },
      ],
      colors: ['#dc67ab', '#a0dc67'],
    }
  })

  return charts
}
