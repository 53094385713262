<template>
  <v-app-bar v-if="edited">
    <v-btn icon="mdi-arrow-left" @click="$router.push('/rdata/configs')" />

    <v-tooltip
      location="bottom start"
      :text="
        edited.name.length === 0 ? 'Name cannot be empty' : !hasUniqueName ? 'File with this name already exists' : ''
      "
      :content-class="{ 'bg-error': true, 'd-none': edited.name.length > 0 && hasUniqueName }"
      open-on-focus
    >
      <template #activator="{ props }">
        <v-text-field
          v-bind="props"
          v-model.trim="edited.name"
          style="max-width: 500px"
          class="text-primary font-weight-bold"
          :readonly="!hasWriteRights"
          :append-inner-icon="edited.name.length === 0 || !hasUniqueName ? 'mdi-alert-outline' : ''"
          @update:focused="
            (edited.name.length === 0 || !hasUniqueName) && !$event ? (edited.name = original!.name) : null
          "
        />
      </template>
    </v-tooltip>

    <span class="text-caption ml-4">{{ hardwareName }}</span>

    <v-spacer />

    <v-tooltip location="bottom" text="Preview JSON">
      <template #activator="{ props }">
        <v-btn v-bind="props" icon="mdi-eye" @click="previewDialog = true" />
      </template>
    </v-tooltip>

    <v-tooltip location="bottom" text="Import JSON">
      <template #activator="{ props }">
        <v-btn v-bind="props" icon="mdi-file-import" @click="importDialog = true" />
      </template>
    </v-tooltip>

    <v-tooltip location="bottom" text="Export JSON">
      <template #activator="{ props }">
        <v-btn v-bind="props" icon="mdi-file-export" @click="exportConfigJson()" />
      </template>
    </v-tooltip>

    <v-btn
      class="ml-4"
      variant="flat"
      text="Save changes"
      :disabled="!isDataCollectionIdValid || !edited.name || !hasDataChanges || !hasWriteRights || !hasUniqueName"
      @click="saveChanges()"
    />
  </v-app-bar>

  <v-container v-if="edited && schema">
    <v-row>
      <v-col cols="12">
        <v-alert class="flex-shrink-0" :color="hasWriteRights ? 'info' : 'warning'">
          <v-row align="center">
            <v-col class="flex-grow-1">
              Last edited by {{ modifiedAt ? 'you' : edited.updatedBy || edited.createdBy }} at
              {{
                $dayjs(modifiedAt || edited.updatedAt?.toDate() || edited.createdAt?.toDate()).format(
                  'HH:mm:ss on DD MMM YYYY',
                )
              }}
            </v-col>

            <v-col class="flex-shrink-1 text-no-wrap text-right">
              {{ hasWriteRights ? (hasDataChanges ? '(unsaved)' : '(saved)') : '(read-only)' }}
            </v-col>
          </v-row>
        </v-alert>

        <v-alert v-if="!!schemaUpdate" class="flex-shrink-0 mt-2" color="warning">
          <v-row align="center">
            <v-col class="flex-grow-1">
              {{ errorText || 'There is a newer research data config schema available' }}
            </v-col>

            <v-col class="flex-shrink-1 text-no-wrap text-right">
              <v-btn text="Update" color="primary" :disabled="!!errorText" @click="updateSchema()" />
            </v-col>
          </v-row>
        </v-alert>

        <v-alert v-if="studiesUsingFile.length" closable class="mt-2" color="warning">
          This file is being used in {{ studiesUsingFile.length > 1 ? 'studies' : 'a study' }}:
          <b>{{ studiesUsingFile.map((study: any) => study.name).join(', ') }}</b>
        </v-alert>
      </v-col>
    </v-row>

    <v-row v-if="edited && schema">
      <v-col cols="12">
        <v-card>
          <v-toolbar>
            <v-toolbar-title>Data collection</v-toolbar-title>

            <v-spacer />

            <v-toolbar-items>
              <div class="text-right pt-2 pr-4">
                <div class="text-primary text-button mb-n2">Recording time estimate</div>
                <div class="text-caption">{{ recordingTimeEstimate(schema, edited.data) }}</div>
              </div>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-text class="pa-8">
            <v-row>
              <v-col cols="4">
                <v-text-field
                  v-model="edited.data.data_collections[0].name"
                  persistent-hint
                  label="Name"
                  hint="This name is shown in the app data collection selector"
                  @update:model-value="updateID()"
                />
              </v-col>

              <v-col cols="4">
                <v-text-field
                  v-model="edited.data.data_collections[0].id"
                  persistent-hint
                  label="ID"
                  hint="Changing this wont affect ongoing studies only new ones"
                  :error-messages="isDataCollectionIdValid ? '' : 'This data collection id already exists!'"
                  @update:model-value="validateDataCollectionId()"
                />
              </v-col>

              <v-col cols="4">
                <v-select
                  v-model="edited.mode"
                  persistent-hint
                  label="Mode"
                  hint="Changing this will clear the currently set configuration"
                  :items="configModes"
                  :disabled="!schema.definitions['ppg_monitor_feature_mask']"
                  @update:model-value="updateMode()"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-textarea v-model="edited.data.data_collections[0].description" label="Description" />
              </v-col>
            </v-row>

            <v-row class="align-center">
              <v-col class="d-flex align-center justify-end" cols="12">
                <v-combobox
                  chips
                  multiple
                  closable-chips
                  label="Manager emails"
                  hint="Managers have permission to edit this config file"
                  :model-value="[edited.createdBy].concat(edited.managerEmails || [])"
                  @update:model-value="updateManagers($event, edited)"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <template v-if="edited.mode === 'manual'">
      <v-row>
        <v-col cols="12">
          <EditingTable sensor="PPG" :config="edited.data" :schema="schema" :disabled="!hasWriteRights" />
        </v-col>
      </v-row>

      <v-row v-if="!!Object.keys(schema.definitions).find((d: string) => d.startsWith('ecg'))">
        <v-col cols="12">
          <EditingTable sensor="ECG" :config="edited.data" :schema="schema" :disabled="!hasWriteRights" />
        </v-col>
      </v-row>

      <v-row v-if="!!Object.keys(schema.definitions).find((d: string) => d.startsWith('bioz'))">
        <v-col cols="12">
          <EditingTable sensor="BIOZ" :config="edited.data" :schema="schema" :disabled="!hasWriteRights" />
        </v-col>
      </v-row>

      <v-row v-if="edited && schema">
        <v-col :cols="hasGyroChannel ? 3 : 4">
          <EditingCard sensor="ACM" :config="edited.data" :schema="schema" :disabled="!hasWriteRights" />
        </v-col>

        <v-col v-if="hasGyroChannel" cols="3">
          <EditingCard sensor="Gyro" :config="edited.data" :schema="schema" :disabled="!hasWriteRights" />
        </v-col>

        <v-col :cols="hasGyroChannel ? 3 : 4">
          <EditingCard sensor="Temperature" :config="edited.data" :schema="schema" :disabled="!hasWriteRights" />
        </v-col>

        <v-col :cols="hasGyroChannel ? 3 : 4">
          <EditingCard sensor="System" :config="edited.data" :schema="schema" :disabled="!hasWriteRights" />
        </v-col>
      </v-row>
    </template>

    <template v-else>
      <v-row>
        <v-col cols="12">
          <MonitoringMask sensor="PPG" :config="edited.data" :schema="schema" :disabled="!hasWriteRights" />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <MonitoringMode sensor="ACM" :config="edited.data" :schema="schema" :disabled="!hasWriteRights" />
        </v-col>

        <v-col>
          <MonitoringMode sensor="TEMP" :config="edited.data" :schema="schema" :disabled="!hasWriteRights" />
        </v-col>
      </v-row>
    </template>
  </v-container>

  <v-dialog v-if="importDialog" v-model="importDialog" width="800">
    <v-card>
      <v-card-title>Import JSON file</v-card-title>

      <v-card-text>
        <v-file-input
          label="Select file"
          accept="application/json"
          @update:model-value="importConfigJson($event as File)"
        />

        <v-alert v-if="errorText" icon="mdi-alert" type="warning" variant="text">
          {{ errorText }}
        </v-alert>
      </v-card-text>
    </v-card>
  </v-dialog>

  <v-dialog v-model="previewDialog" width="1000">
    <JsonPreview :edited="edited && edited.data" :original="original && original.data" />
  </v-dialog>
</template>

<script lang="ts">
  import Ajv from 'ajv'

  import sortKeys from 'sort-keys'

  import { cloneDeep, isEqual, snakeCase } from 'lodash-es'

  import { Component, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { Debounce, RING_TYPE, getRingTypeByValue } from '@jouzen/outo-toolkit-vuetify'

  import { configModes } from '#views/rdata/constants'

  import {
    getFieldItems,
    getFieldValue,
    recordingTimeEstimate,
    setFieldValue,
    updateManagers,
  } from '#views/rdata/utilities'

  import { AppStore, RdataStore, TeamsStore } from '#stores'

  import { RdataFile, Study, StudyFile } from '#types'

  @Component
  class ConfigEditor extends Vue {
    @Prop() public fid!: string

    public errorText = ''

    public modifiedAt = 0

    public schema: any = null

    public edited: any = null
    public original: any = null

    public importDialog = false
    public previewDialog = false

    public exportingJson = false

    public schemaUpdate: any = null

    public isDataCollectionIdValid = true

    public readonly configModes = configModes

    public readonly getFieldItems = getFieldItems
    public readonly getFieldValue = getFieldValue
    public readonly setFieldValue = setFieldValue

    public readonly updateManagers = updateManagers

    public readonly recordingTimeEstimate = recordingTimeEstimate

    protected readonly appStore = new AppStore()
    protected readonly rdataStore = new RdataStore()
    protected readonly teamsStore = new TeamsStore()

    public get rdataFiles() {
      return this.rdataStore.files
    }

    public get rdataSchemas() {
      return this.rdataStore.schemas
    }

    public get hardwareName() {
      const ringType = RING_TYPE[this.edited.data.hardware_type as keyof typeof RING_TYPE]

      return getRingTypeByValue(ringType)?.title ?? 'Unknown'
    }

    public get hasUniqueName() {
      return !this.rdataFiles.some(
        (f: RdataFile) => f.id !== this.edited.id && f.name.toLowerCase() === this.edited.name.toLowerCase(),
      )
    }

    public get hasDataChanges() {
      return this.edited && !isEqual(this.edited, this.original)
    }

    public get hasWriteRights() {
      return (
        this.appStore.isResearchDataAdmin ||
        this.appStore.user?.email === this.edited?.createdBy ||
        this.original?.managerEmails?.includes(this.appStore.user?.email)
      )
    }

    public get hasGyroChannel() {
      return this.getFieldItems('Gyro', this.schema, 'measurement_channel_description_id').length > 0
    }

    public get studiesUsingFile() {
      return this.teamsStore.teams
        .filter((study: Study) => {
          return study.studyFiles.some((file: StudyFile) => {
            return file.id === this.fid
          })
        })
        .sort((a, b) => a.name.localeCompare(b.name))
    }

    @Watch('edited', { deep: true })
    protected editedChanged() {
      if (this.edited) {
        this.rdataStore.updateLiveSync(this.edited)
      }
    }

    @Watch('original', { immediate: true })
    protected originalChanged() {
      if (this.edited) {
        this.rdataStore.updateLiveSync(this.edited)

        this.schema = JSON.parse(this.edited.schema)

        this.checkSchemaUpdates()
      }
    }

    @Watch('rdataFiles', { immediate: true })
    protected rdataFilesChanged() {
      const file = this.rdataFiles.find((f: RdataFile) => f.id === this.fid)

      if (file) {
        this.original = file

        this.edited = cloneDeep(this.original)

        this.edited.createdAt = this.original.createdAt
        this.edited.updatedAt = this.original.updatedAt
      }
    }

    public created() {
      window.addEventListener('beforeunload', () => {
        this.rdataStore.updateLiveSync(null)
      })
    }

    public beforeUnmount() {
      this.rdataStore.updateLiveSync(null)
    }

    public saveChanges() {
      this.rdataStore.updateFile({ ...this.edited })

      this.checkSchemaUpdates()
    }

    public updateID() {
      if (!this.edited?.data?.data_collections[0].id && !this.original?.data?.data_collection[0].id) {
        this.edited.data.data_collection[0].id = snakeCase(this.edited.data.data_collection[0].name)
      }
    }

    public updateMode() {
      if (this.edited.mode !== 'monitor') {
        this.edited.data.data_collections[0].measurements = []
      } else {
        this.edited.data.data_collections[0].measurements = [
          {
            name: 'Rdata monitor mode',
            description: '',
            parameters: [
              {
                name: this.schema.definitions['ppg_monitor_feature_mask'].properties.value.title,
                class_id: this.schema.definitions['ppg_monitor_feature_mask'].properties.class_id.default,
                param_id: this.schema.definitions['ppg_monitor_feature_mask'].properties.param_id.default,
                length: this.schema.definitions['ppg_monitor_feature_mask'].properties.length.default,
                value: this.schema.definitions['ppg_monitor_feature_mask'].properties.value.default,
              },
              {
                name: this.schema.definitions['acm_monitor'].properties.value.title,
                class_id: this.schema.definitions['acm_monitor'].properties.class_id.default,
                param_id: this.schema.definitions['acm_monitor'].properties.param_id.default,
                length: this.schema.definitions['acm_monitor'].properties.length.default,
                value: this.schema.definitions['acm_monitor'].properties.value.default,
              },
              {
                name: this.schema.definitions['temp_monitor'].properties.value.title,
                class_id: this.schema.definitions['temp_monitor'].properties.class_id.default,
                param_id: this.schema.definitions['temp_monitor'].properties.param_id.default,
                length: this.schema.definitions['temp_monitor'].properties.length.default,
                value: this.schema.definitions['temp_monitor'].properties.value.default,
              },
            ],
          },
        ]
      }
    }

    public updateSchema() {
      const isMigrationNeeded =
        this.schema.definitions['ppg_adc_range'] && !this.schemaUpdate.definitions['ppg_adc_range']

      this.edited.data.hardware_type = this.schemaUpdate.properties.hardware_type.default

      this.edited.schema = JSON.stringify(this.schemaUpdate)

      this.schema = JSON.parse(this.edited.schema)

      if (isMigrationNeeded) {
        for (const slot of this.getFieldItems('PPG', this.schema, 'measurement_slot_id')) {
          if (this.getFieldValue(slot.value, 'PPG', this.schema, this.edited.data, 'ppg_ppg1_adc_range')) {
            this.setFieldValue(
              slot.value,
              'PPG',
              this.schema,
              this.edited.data,
              'ppg_ppg2_adc_range',

              this.getFieldValue(slot.value, 'PPG', this.schema, this.edited.data, 'ppg_ppg1_adc_range'),
            )
          }

          if (this.getFieldValue(slot.value, 'PPG', this.schema, this.edited.data, 'ppg_ppg1_dac_offset')) {
            this.setFieldValue(
              slot.value,
              'PPG',
              this.schema,
              this.edited.data,
              'ppg_ppg2_dac_offset',
              this.getFieldValue(slot.value, 'PPG', this.schema, this.edited.data, 'ppg_ppg1_dac_offset'),
            )
          }
        }
      }

      this.previewDialog = true
      this.schemaUpdate = null
    }

    public exportConfigJson() {
      this.exportingJson = true

      const link = document.createElement('a')
      const data = encodeURI(
        'data:text/json;charset=utf-8,' +
          JSON.stringify(sortKeys(JSON.parse(JSON.stringify(this.edited.data)), { deep: true }), null, 2),
      )

      link.setAttribute('href', data)
      link.setAttribute('download', this.edited.name.replaceAll(' ', '_') + '.json')
      link.click()

      setTimeout(() => (this.exportingJson = false), 1000)
    }

    public importConfigJson(file: File) {
      if (file) {
        this.errorText = ''

        const reader = new FileReader()

        reader.onload = (event) => {
          if (event?.target?.result) {
            const data = JSON.parse(event.target.result as string)

            const dataIsValid = this.validateDataAgainstSchema(data)

            data.hardware_type = RING_TYPE[data.hardware_type as keyof typeof RING_TYPE]

            if (data.hardware_type !== this.edited.data.hardware_type) {
              this.errorText =
                'Hardware type does not match (expected: ' +
                this.edited.data.hardware_type +
                ', imported: ' +
                data.hardware_type +
                ')'
            } else if (data.format !== this.edited.data.format) {
              this.errorText =
                'File format does not match (expected: ' + this.edited.data.format + ', imported: ' + data.format + ')'
            } else if (dataIsValid !== true) {
              this.errorText = 'Invalid JSON file: ' + dataIsValid
            } else {
              this.edited.data.data_collections = data.data_collections

              this.updateLiveDataForORT()

              this.importDialog = false
            }
          }
        }

        reader.readAsText(file)
      }
    }

    public validateDataCollectionId() {
      this.isDataCollectionIdValid = !this.rdataFiles.find(
        (file: RdataFile) =>
          file.id !== this.edited.id && file.data.data_collections[0].id === this.edited.data.data_collections[0].id,
      )
    }

    @Debounce(500)
    private checkSchemaUpdates() {
      const schema = this.rdataSchemas.find((s) => s.name === this.schema.name)

      this.schemaUpdate = !isEqual(this.schema, schema) ? schema : null

      if (this.schemaUpdate) {
        const data = { ...this.edited.data, hardware_type: this.schemaUpdate.properties.hardware_type.default }

        const dataIsValid = this.validateDataAgainstSchema(data)

        if (!dataIsValid) {
          this.errorText = 'Newer schema available, but there are errors: ' + dataIsValid
        }
      } else {
        this.errorText = ''
      }
    }

    @Debounce(500)
    private updateLiveDataForORT() {
      this.modifiedAt = Date.now()

      this.rdataStore.updateLiveSync(this.edited)
    }

    private validateDataAgainstSchema(data: any) {
      data = cloneDeep(data)

      const ajv = new Ajv({ strict: false })

      const validate = ajv.compile(this.schema)

      // JSON schema dont currently support bitmask format

      const dataSchema = this.schema.definitions['ppg_monitor_feature_mask']

      data.data_collections[0].measurements.forEach((m: any) => {
        m.parameters.forEach((p: any) => {
          if (
            p.length === dataSchema?.properties?.length.default &&
            p.class_id === dataSchema?.properties?.class_id.default &&
            p.param_id === dataSchema?.properties?.param_id.default
          ) {
            p.value = 0
          }
        })
      })

      return validate({ ...data }) ? true : ajv.errorsText(validate.errors)
    }
  }

  export default toNative(ConfigEditor)
</script>
